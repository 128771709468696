<template>
  <div class="page-switcher mt-6">
    <p class="ma-0 fs-16 lh-normal">
      {{
        isLogin
          ? 'Don\'t have an account?'
          : 'Already have an account?'
      }}
    </p>
    <router-link
      :to="{ name: redirectUrl }"
      class="blue--text fs-16 lh-normal text-decoration-underline">
      {{ isLogin ? 'Sign up' : 'Login' }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'LoginPageSwitcher',
  props: {
    origin: {
      type: String,
      default: 'login',
      validator: (value) => {
        return ['login', 'signup', 'event-login', 'event-signup'].includes(value);
      },
    },
  },
  computed: {
    isLogin() {
      return ['login', 'event-login'].includes(this.origin);
    },
    redirectUrl() {
      switch (this.origin) {
      case 'signup':
        return 'login';

      case 'event-login':
        return 'event-sign-up';

      case 'event-signup':
        return 'event-login';
      }
      return 'sign-up';
    },
  },
};
</script>
<style lang="scss" scoped>
.page-switcher {
  margin-inline: auto;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
</style>