<template>
  <AppLoginPageLayout class="justify-start">
    <template #before-wrapper>
      <PageSwitcher :origin="origin" />
    </template>

    <!-- used for events page -->
    <slot name="before-form" />

    <template>
      <h1 class="sign-up__title text-center">
        Sign up
      </h1>
      <p class="sign-up__content mt-2 text-center">
        Create your free Sourcery workspace.
      </p>
      <v-form
        class="mt-8"
        @submit.prevent="signUpSubmit">
        <label class="sourcery__form">
          Name*
          <v-text-field
            v-model="signUp.name"
            :append-icon="!!nameErrors.length? 'mdi-alert-circle-outline' : ''"
            :error="!!nameErrors.length"
            :error-messages="nameErrors"
            :height="48"
            autocomplete="name"
            class="mt-2"
            data-test="sign_up_name_input"
            dense
            outlined
            single-line />
        </label>

        <label class="sourcery__form">
          Email*
          <v-text-field
            v-model="initialCaseEmail"
            :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
            :error="!!emailErrors.length "
            :error-messages="emailErrors"
            :height="48"
            autocomplete="email"
            class="mt-2"
            data-test="sign_up_email_input"
            type="email"
            dense
            outlined
            single-line
            @input="error = null" />
        </label>

        <label class="sourcery__form">
          Password*
          <v-text-field
            v-model="signUp.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :error="!!passwordErrors.length"
            :error-messages="passwordErrors"
            :height="48"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="new-password"
            class="mt-2"
            data-test="sign_up_password_input"
            dense
            outlined
            single-line
            @click:append="showPassword = !showPassword" />
        </label>

        <AgreeTermsConditions :agree-terms-conditions.sync="agreeTermsConditions" />

        <v-btn
          :disabled="!agreeTermsConditions"
          :elevation="0"
          data-test="sign_up_button"
          class="mt-6 white--text sourcery__big-pill"
          color="blue"
          type="submit"
          block
          rounded>
          Sign up
        </v-btn>

        <LoginUsingSocial
          class="sourcery__big-pill"
          :origin="origin" />
      </v-form>
    </template>

    <slot name="after-form" />
  </AppLoginPageLayout>
</template>
<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import { Auth } from 'aws-amplify';
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';

import ErrorsText from '@/constants/errors';
import AWS from '@/services/awsSdk';
import paymentAPI from '@/services/graphql/payments';

import AgreeTermsConditions from '@/components/SignUp/SignUpAgreeTermsConditions';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import PageSwitcher from '@/components/App/AppLoginPageLayout/PageSwitcher';
import LoginUsingSocial from '@/components/App/AppLoginPageLayout/LoginUsingSocial';

import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
export default {
  name: 'SignUp',
  components: {
    AgreeTermsConditions,
    AppLoginPageLayout,
    PageSwitcher,
    LoginUsingSocial,
  },
  mixins: [ConverEmailToLowerCase],
  props: {
    origin: {
      type: String,
      default: 'signup',
    },
  },
  data: () => ({
    showPassword: false,
    agreeTermsConditions: true,
    error: null,
    pageObjName: 'signUp',
    signUp: {
      name: null,
      email: null,
      password: null,
      initialCaseEmail: null,
    },
  }),
  validations: {
    signUp: {
      name: {
        required,
      },
      email: {
        required, email,
      },
      password: {
        required, minLength: minLength(8),
      },
    },
  },
  computed: {
    ...mapState(['domain', 'isMobile']),
    nameErrors() {
      const errors = [];
      if (!this.$v.signUp.name.$dirty) return errors;
      if (!this.$v.signUp.name.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.signUp.email.$dirty) return errors;
      if (!this.$v.signUp.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.signUp.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error) {
        errors.push(this.error);
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.signUp.password.$dirty) return errors;
      if (!this.$v.signUp.password.minLength) {
        errors.push('Passwords must be at least 8 characters long');
      }
      if (!this.$v.signUp.password.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    viewParamsPayment() {
      const { tire, role, interval } = this.queryPayment;
      return [{
        id: 'viewId', value: 'payment',
      },
      {
        id: 'tire', value: tire,
      },
      {
        id: 'role', value: role,
      },
      {
        id: 'interval', value: interval,
      },
      {
        id: 'redirect', value: 'payment',
      },
      ];
    },
    isFreePlan() {
      return this.$route.query?.role === 'Free User';
    },
    queryPayment() {
      let queryPayment = {
      };
      const query = this.$route.query;
      query?.tire && query?.role && query?.interval ? queryPayment = query : queryPayment = null;
      return queryPayment;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError']),
    async signUpSubmit() {
      this.$v.signUp.$touch();
      if (!this.$v.signUp.$invalid && !this.error) {
        const { email, password, name } = this.signUp;
        this.spinner(true);
        try {
          const user = await Auth.signUp({
            username: email,
            password,
            attributes: {
              name,
            },
            clientMetadata: {
              mobile: this.isMobile.toString(),
              domain: this.domain,
            },
          });
          if (this.queryPayment && !this.isFreePlan) {
            try {
              const username = user.userSub;
              await paymentAPI.setViewParams({
                username,
                params: this.viewParamsPayment,
              });
            } catch (err) {
              this.handleError(err);
            }
          }
          this.forceLogin({
            email,
            password,
          });
        } catch (err) {
          this.spinner(false);
          if (err.message === 'An account with the given email already exists.') {
            this.error = err.message;
          }
        }
      }
    },
    forceLogin({ email, password } = {
    }) {
      if (email && password) {
        const dataToSend = {
          email,
          password,
        };
        AWS.login(dataToSend, this);
      }
    },
  },
};
</script>
