export default {
  methods: {
    handleEmailLowerCase({ obj = 'login', val } = {
    }) {
      this[obj].email = val ? val.toLowerCase() : null;
      this[obj].initialCaseEmail = val;
    },
  },
  computed: {
    initialCaseEmail: {
      get() {
        return this[this.pageObjName]?.initialCaseEmail;
      },
      set(val) {
        this.handleEmailLowerCase({
          obj: this.pageObjName,
          val,
        });
      },
    },
  },
};
