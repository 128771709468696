<template>
  <v-btn
    :elevation="0"
    class="social-login mt-4 lh-normal"
    color="black"
    block
    outlined
    @click="signInGoogle">
    <img src="@/assets/icons/google.svg">
    {{
      ['login', 'event-login'].includes(origin)
        ? 'Log in with Google'
        : 'Sign up with Google'
    }}
  </v-btn>
</template>
<script>
import { Auth } from 'aws-amplify';
import { mapMutations } from 'vuex';
export default {
  name: 'LoginUsingSocial',
  props: {
    origin: {
      type: String,
      default: 'login',
      validator: (value) => {
        return ['login', 'signup', 'event-login', 'event-signup'].includes(value);
      },
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    async signInGoogle() {
      try {
        this.spinner(true);
        await Auth.federatedSignIn({
          provider: 'Google',
        });
      } catch (err) {
        this.spinner(false);
        console.log('err feder', err);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.social-login {
  ::v-deep .v-btn__content {
    gap: 12px;
  }
}
</style>